export default (theme) => ({
  variants: [
    {
      props: { variant: "plan-warning" },
      style: {
        color: theme.palette.primary.main,
        fontStyle: "italic",
        fontSize: 10.5
      },
    },
  ],
});
