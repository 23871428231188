import { isDate } from "date-fns";
import { formatDate } from "./dates";

const userAgent = "gj-employer-fetcher/v1.0";
function parseDataToString(data) {
  let d;
  if (data) {
    d = {};
    Object.keys(data).forEach((k) => {
      const v = data[k];
      switch (typeof v) {
        case "object":
          if (isDate(v)) {
            d[k] = formatDate(v);
          } else if (Array.isArray(v)) {
            d[k] = v;
          } else {
            d[k] = parseDataToString(v);
          }
          break;
        default:
          d[k] = v;
          break;
      }
    });
  }
  return d;
}

export function getUrlNBody(url, data, method = "GET") {
  switch (method) {
    case "GET":
      return {
        url: `${url}${
          data
            ? `?${new URLSearchParams(parseDataToString(data)).toString()}`
            : ""
        }`,
      };
  }
  return {
    url,
    body: JSON.stringify(parseDataToString(data)),
  };
}

export default async function fetcher(
  rawUrl,
  data,
  method = "GET",
  headers = {}
) {
  const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "User-Agent": userAgent,
  };

  const { url, body } = getUrlNBody(rawUrl, data, method);
  const res = await fetch(url, {
    headers: { ...defaultHeaders, ...headers },
    method,
    body,
  });
  if (!res.ok) {
    let error = new Error("HTTP status code: " + res.status);
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
}

export async function postFetcher(rawUrl, data) {
  return fetcher(rawUrl, data, "POST");
}
export async function putFetcher(rawUrl, data) {
  return fetcher(rawUrl, data, "PUT");
}
