import React, { memo } from "react";
import Image from "next/image";
import { Box } from "@mui/material";

export default memo(function Logo({ width = 180, height = 30 }) {
  return (
    <Box sx={{ width, height }}>
      <Image
        src={"/gj_logo_new.svg"}
        alt="GrabJobs"
        loading="lazy"
        layout="responsive"
        // placeholder="blur"
        width={width}
        height={height}
      />
    </Box>
  );
});
