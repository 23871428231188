import React from "react";
import { MenuItem, Stack, Box, Link, Typography } from "@mui/material";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { useIntl } from "react-intl";

export default function Support({
  component: Component = MenuItem,
  icon,
  children,
  ...props
}) {
  const intl = useIntl();
  return (
    <Component component={Link} target="_blank" {...props}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Stack direction="row" gap={0.5}>
          {icon}
          <Typography fontSize={14} color="text.secondary">
            {children}
          </Typography>
        </Stack>
        <LaunchOutlinedIcon sx={{ fontSize: 13 }} />
      </Stack>
    </Component>
  );
}
