import {
  setCookie as setNextCookie,
  getCookies,
  deleteCookie,
} from "cookies-next";

import { APP_COOKIE_MAX_AGE } from "config/app";
export const COOKIE_MAX_AGE_MONTH = 2592000;

const cookieOptions = {
  path: "/",
  domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
  secure: process.env.NODE_ENV === "production",
};

export function setCookie(
  req,
  res,
  name,
  value,
  maxAge = APP_COOKIE_MAX_AGE,
  httpOnly = false
) {
  setNextCookie(name, value, {
    req,
    res,
    ...cookieOptions,
    httpOnly,
    maxAge,
  });
}

export function readCookie(name, req = null) {
  const cookies = getCookies({ req });
  return decodeURIComponent(
    (typeof cookies?.get === "function" ? cookies?.get(name) : cookies[name]) ??
      ""
  );
}

export function removeCookie(req, res, name, httpOnly = false) {
  deleteCookie(name, { req, res, ...cookieOptions, httpOnly, maxAge: 0 });
}
