export const DISABLED_ANALYTIC_KEY = "disabled";

export const FB_PIXEL_API_KEY = process.env.NEXT_PUBLIC_FB_PIXEL_API_KEY;
export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;
export const GTM_CONTAINER_ID =
  process.env.NEXT_PUBLIC_GTM_CONTAINER_ID !== DISABLED_ANALYTIC_KEY &&
  process.env.NEXT_PUBLIC_GTM_CONTAINER_ID;
export const ZENDESK_KEY = process.env.NEXT_PUBLIC_ZENDESK_KEY;
export const GOOGLE_OPTIMIZE_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_KEY !== DISABLED_ANALYTIC_KEY &&
  process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_KEY;
export const USERPILOT_API_TOKEN = process.env.NEXT_PUBLIC_USERPILOT_API_TOKEN;
