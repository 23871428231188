export default (theme) => ({
  styleOverrides: {
    root: {
      marginLeft: theme.spacing(1.4),
    },
    line: {
      minHeight: 40,
      borderLeftWidth: 2,
    },
  },
});
