import useCustomSWR from "utils/use-swr";
import { IPINFO_TOKEN } from "config/app";
import fetcher from "utils/fetcher";

export default function useIP() {
  return useCustomSWR(
    IPINFO_TOKEN && `https://ipinfo.io/json?token=${IPINFO_TOKEN}`,
    fetcher
  );
}

export async function getIPInfo(ip) {
  return fetcher(`https://ipinfo.io/${ip}/json?token=${IPINFO_TOKEN}`);
}
