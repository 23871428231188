export default (intl) => [
  {
    code: "en",
    title: intl.formatMessage({ id: "English", defaultMessage: "English" }),
  },
  {
    code: "fr",
    title: intl.formatMessage({ id: "French", defaultMessage: "French" }),
    countries: ["FR"],
  },
  {
    code: "es",
    title: intl.formatMessage({ id: "Spanish", defaultMessage: "Spanish" }),
    countries: ["ES"],
  },
  {
    code: "id",
    title: intl.formatMessage({
      id: "Indonesian",
      defaultMessage: "Indonesian",
    }),
    countries: ["ID"],
  },
];
