import { V1_URL } from "config/app";

export const APP_BASE =
  process.env.NEXT_PUBLIC_APP_BASE ?? "http://localhost:3000";

export const HRMS_BASE =
  process.env.NEXT_PUBLIC_HRMS_BASE ?? "http://localhost:3001";

export const HRMS_LINK = `${HRMS_BASE}/hr`;

export const GUARDED_ROUTE = "/recruitment";
export const ROUTE_FORGET_PASSWORD = "/forgot";
export const ROUTE_LOGIN = "/";

export const ROUTE_REGISTER = "/register";
export const ROUTE_REGISTER_COMPLETE = "/register/complete";
export const ROUTE_VERIFICATION = "/auth/pending_verification";
export const ROUTE_DASHBOARD = GUARDED_ROUTE;

export const ROUTE_TNC = "https://grabjobs.co/terms";
export const ROUTE_PRIVACY = "https://grabjobs.co/privacy";

export const ROUTE_JOB = `${GUARDED_ROUTE}/job`;

//job post
export const ROUTE_JOB_POST = `${GUARDED_ROUTE}/job/post`;

//job edit
export const ROUTE_JOB_EDIT_STEP1 = `${GUARDED_ROUTE}/job/:jobId/edit/step1`;
export const ROUTE_JOB_EDIT_STEP2 = `${GUARDED_ROUTE}/job/:jobId/edit/step2`;
export const ROUTE_JOB_EDIT_STEP3 = `${GUARDED_ROUTE}/job/:jobId/edit/step3`;
export const ROUTE_JOB_EDIT_STEP_REVIEW = `${GUARDED_ROUTE}/job/:jobId/edit/review`;

//job repost
export const ROUTE_JOB_REPOST_STEP1 = `${GUARDED_ROUTE}/job/:jobId/repost/step1`;
export const ROUTE_JOB_REPOST_STEP2 = `${GUARDED_ROUTE}/job/:jobId/repost/step2`;
export const ROUTE_JOB_REPOST_STEP3 = `${GUARDED_ROUTE}/job/:jobId/repost/step3`;
export const ROUTE_JOB_REPOST_STEP_REVIEW = `${GUARDED_ROUTE}/job/:jobId/repost/review`;

//job create
export const ROUTE_JOB_POST_PRO_STEP1 = `${GUARDED_ROUTE}/job/post/pro/step1`;
export const ROUTE_JOB_POST_PRO_STEP2 = `${GUARDED_ROUTE}/job/post/pro/step2`;
export const ROUTE_JOB_POST_PRO_STEP3 = `${GUARDED_ROUTE}/job/post/pro/step3`;
export const ROUTE_JOB_POST_PRO_STEP_REVIEW = `${GUARDED_ROUTE}/job/post/pro/review`;
export const ROUTE_JOB_POST_SUCCESS = `${GUARDED_ROUTE}/job/:jobId/success`;
export const ROUTE_JOB_POST_URL_STEP1 = `${GUARDED_ROUTE}/job/post/url/step1`;
export const ROUTE_JOB_POST_URL_STEP2 = `${GUARDED_ROUTE}/job/post/url/step2`;
export const ROUTE_JOB_POST_URL_STEP_REVIEW = `${GUARDED_ROUTE}/job/post/url/review`;

//jobs
export const ROUTE_JOB_ACTIVE = `${GUARDED_ROUTE}/job/active_job`;
export const ROUTE_JOB_CLOSE = `${GUARDED_ROUTE}/job/close_job`;
export const ROUTE_JOB_LIST = "/job/template_job_list";
export const ROUTE_JOB_PROMOTE = `${GUARDED_ROUTE}/job/promote_job`;

export const ROUTE_CANDIDATE_LIST = `${GUARDED_ROUTE}/job/:jobId/candidate-list/:atsId`;
export const ROUTE_CANDIDATE_LIST_AUTOMATION = `${GUARDED_ROUTE}/job/:jobId/candidate-list/automation`;

//candidates
export const ROUTE_CANDIDATE_TOP_MATCHING = `${GUARDED_ROUTE}/candidate/top_matching_candidates`;
export const ROUTE_CANDIDATE_SEARCH = `${GUARDED_ROUTE}/candidates-search`;
export const ROUTE_CVSEARCH_PROFILE = `${GUARDED_ROUTE}/cvsearch_profile`;
export const ROUTE_MY_CANDIDATES = `${GUARDED_ROUTE}/my-candidates`;
export const ROUTE_CANDIDATE_PROFILE = `${GUARDED_ROUTE}/candidate/candidate_profile`;
export const ROUTE_TOP_CANDIDATE_PROFILE = `${GUARDED_ROUTE}/candidate/top_candidate_profile`;
export const ROUTE_CANDIDATE_PROFILE_STATIC = `${GUARDED_ROUTE}/candidate/candidate_profile?job=:job&seeker_id=:seeker_id&job_application_id=:job_application_id&top_match=:top_match&note=:note&ats_id=:ats_id`;

//plan
export const ROUTE_PLANS = `${GUARDED_ROUTE}/plans`;
export const ROUTE_COINS = `${GUARDED_ROUTE}/plans/coins`;
export const ROUTE_CVSEARCH = `${GUARDED_ROUTE}/plans/cvsearch`;
export const ROUTE_PLAN_JOBPOSTING = `${GUARDED_ROUTE}/plans/jobposting`;
//calendar
export const ROUTE_CALENDAR = `${GUARDED_ROUTE}/calendar`;
export const ROUTE_INTERVIEW_CALENDAR = `${GUARDED_ROUTE}/calendar`;

//analytics
export const ROUTE_ANALYTICS = `${GUARDED_ROUTE}/analytics`;

//brand
export const ROUTE_BRAND_PROFILE = `${GUARDED_ROUTE}/brand/list`;
export const ROUTE_BRAND_PROFILE_EDIT = `${GUARDED_ROUTE}/brand/edit`;
export const ROUTE_BRAND_OUTLETS = `${GUARDED_ROUTE}/brand/outlets`;

//setting
export const ROUTE_SETTINGS = `${GUARDED_ROUTE}/settings`;

//account
export const ROUTE_ACCOUNT = `${GUARDED_ROUTE}/settings/account`;

//team
export const ROUTE_MEMBERS = `${GUARDED_ROUTE}/team_member`;

//company profile
export const ROUTE_COMPANY_PROFILE = `${GUARDED_ROUTE}/company/company_profile`;

//chat
export const ROUTE_CHAT = `${GUARDED_ROUTE}/chat`;
export const ROUTE_CHAT_USER = `${GUARDED_ROUTE}/chat/:email`;

//plugins
export const ROUTE_PLUGINS = `${GUARDED_ROUTE}/plugins`;

//previous platfomr
export const ROUTE_PREVIOUS_PLATFORM = `${V1_URL}/landing.html#!/login`;

//support
export const ROUTE_CANDIDATE_SUPPORT =
  "https://employer-support.grabjobs.co/article/58-about-top-matching-candidates";

export const ROUTE_RESOURCES =
  "https://grabjobs.co/recruitment-platform/resources/";

export const ROUTE_SUPPORT = "https://employer-support.grabjobs.co/";

export const ROUTE_ABOUT_PLANS =
  "https://employer-support.grabjobs.co/article/14-plans-coins-guide";
