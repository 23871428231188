import React from "react";
import Error from "./error";
import { ErrorBoundary } from "@sentry/react";

function CustomErrorBoundary({ children }) {
  return (
    <ErrorBoundary fallback={<Error />}>
      {children}
    </ErrorBoundary>
  );
}
// class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);

//     // Define a state variable to track whether is an error or not
//     this.state = { hasError: false };
//   }
//   static getDerivedStateFromError() {
//     // Update state so the next render will show the fallback UI

//     return { hasError: true };
//   }
//   componentDidCatch(error, errorInfo) {
//     // You can use your own error logging service here
//     this.setState({ error, errorInfo, hasError: true });
//   }
//   render() {
//     // Check if the error is thrown
//     if (this.state.hasError) {
//       // You can render any custom fallback UI
//       return (
//         <Error error={this.state?.error} errorInfo={this.state?.errorInfo} />
//       );
//     }

//     // Return children components in case of no error

//     return this.props.children;
//   }
// }

export default CustomErrorBoundary;
