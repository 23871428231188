import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import palette from "./palette";
import MuiFormLabel from "./components/MuiFormLabel";
import MuiButton from "./components/MuiButton";
import MuiToggleButtonGroup from "./components/MuiToggleButtonGroup";
import MuiStepConnector from "./components/MuiStepConnector";
import MuiGrid from "./components/MuiGrid";
import MuiStack from "./components/MuiStack";
import MuiAccordion from "./components/MuiAccordion";
import MuiAccordionSummary from "./components/MuiAccordionSummary";
import MuiTypography from "./components/MuiTypography";
import MuiDialogTitle from "./components/MuiDialogTitle";
import MuiTab from "./components/MuiTab";
import MuiPopper from "./components/MuiPopper";
import MuiLink from "./components/MuiLink";

const spacing = 2;
// Create a theme instance.
let theme = createTheme({
  palette,
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

theme = {
  ...theme,
  components: {
    // Name of the component
    MuiFormLabel: MuiFormLabel(theme),
    MuiButton: MuiButton(theme),
    MuiToggleButtonGroup: MuiToggleButtonGroup(theme),
    MuiStepConnector: MuiStepConnector(theme),
    MuiGrid: MuiGrid(theme, spacing),
    MuiStack: MuiStack(theme, spacing),
    MuiAccordion: MuiAccordion(theme, spacing),
    MuiAccordionSummary: MuiAccordionSummary(theme),
    MuiTypography: MuiTypography(theme),
    MuiDialogTitle: MuiDialogTitle(theme),
    MuiTab: MuiTab(theme),
    MuiPopper: MuiPopper(theme),
    MuiLink: MuiLink(theme),
  },
};

export default responsiveFontSizes(theme);
