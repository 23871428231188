import React, { forwardRef } from "react";
import { Menu, MenuItem } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { useOpenCloseAnchor } from "hooks/useOpenClose";

const StyledMenu = styled(Menu)(() => ({
  "& .MuiMenu-paper": {
    borderRadius: 10,
  },
  "& .MuiMenu-list": {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1, 4),
  fontSize: 14,
  "&:hover": {
    backgroundColor: alpha(theme.palette.blue.main, 0.08),
  },
  "&:first-child": {
    paddingTop: theme.spacing(2),
  },
  "&:last-child": {
    paddingBottom: theme.spacing(2),
  },
}));

export default forwardRef(function CustomMenu(
  {
    options = [],
    optionLabel = "label",
    optionValue = "value",
    onChange,
    children,
    ...props
  },
  ref
) {
  const { anchor } = useOpenCloseAnchor(ref);

  return (
    <StyledMenu
      anchorEl={anchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchor)}
      sx={{
        zIndex: 9999,
        list: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      }}
      keepMounted
      {...props}
    >
      {children}
      {options.map(({ [optionLabel]: label, [optionValue]: value }) => (
        <StyledMenuItem
          color="black"
          key={value}
          onClick={() => onChange(value)}
        >
          {label}
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );
});
