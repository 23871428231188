import React, { useRef } from "react";
import { IconButton, Badge } from "@mui/material";
import { useLocale } from "hooks/useLocale";
import getLanguages from "./languages";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { useIntl } from "react-intl";
import LanguageMenu from "./menu";

export default function LanguageSelectIcon(props) {
  const intl = useIntl();
  const menuRef = useRef();
  const { locale } = useLocale();
  const languages = getLanguages(intl);
  const onOpen = (event) => menuRef.current.open(event.currentTarget);

  return (
    <>
      <IconButton
        onClick={onOpen}
        endIcon={<ArrowDropDownIcon />}
        color="grey"
        title={intl.formatMessage({
          id: "Language",
          defaultMessage: "Language",
        })}
      >
        <Badge
          badgeContent={languages
            .find((l) => l.code === locale)
            .code?.toUpperCase()}
          color="grey"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <LanguageOutlinedIcon color="black" />
        </Badge>
      </IconButton>
      <LanguageMenu ref={menuRef} intl={intl} />
    </>
  );
}
