import React from "react";
import { MenuItem, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import { ROUTE_LOGIN } from "config/routes";
import { URL_LOGOUT } from "config/endpoints";
import { postFetcher } from "utils/fetcher";
import { useSnackbar } from "notistack";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { clearSessionStorage } from "hooks/useSessionStorage";

export default function Logout({ component: Component = MenuItem }) {
  const router = useRouter();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const onLogout = async () => {
    try {
      clearSessionStorage();
      await postFetcher(URL_LOGOUT);
      router.push(ROUTE_LOGIN);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Component onClick={onLogout} size="small">
      <LogoutOutlinedIcon />
      <Typography
        component="span"
        variant="span"
        textAlign="center"
        sx={{ mx: 1 }}
        color="text.secondary"
      >
        {intl.formatMessage({
          id: "Log Out",
          defaultMessage: "Log Out",
        })}
      </Typography>
    </Component>
  );
}
