import React from "react";
import { useIntl } from "react-intl";
import { Button, Stack, Typography } from "@mui/material";
import { ROUTE_DASHBOARD } from "config/routes";
import { TerminalOutlined } from "@mui/icons-material";
import AppBar from "components/Layout/Main/AppBar";
import { useRouter } from "next/router";
import { useUser } from "hooks/useUser";
import { showReportDialog } from "@sentry/react";

export default function Error({ error, errorInfo }) {
  const intl = useIntl();
  const router = useRouter();
  const {
    user: { user, name },
  } = useUser();

  const onReport = () => showReportDialog({ user: { email: user, name } });
  const onRetry = () => router.reload();
  return (
    <>
      <AppBar />
      <Stack
        sx={{
          height: "100vh",
          flexFlow: "column wrap",
          justifyContent: "center",
        }}
        gap={3}
        alignItems="center"
      >
        <TerminalOutlined sx={{ fontSize: "100px" }} />
        <Typography sx={{ fontSize: "28px", fontWeight: 700 }}>
          {intl.formatMessage({
            id: "Whoops, an unexpected error has occured",
            defaultMessage: "Whoops, an unexpected error has occured",
          })}
        </Typography>
        <Typography
          sx={{ fontSize: "18px", color: "#7A7A7B", textAlign: "center" }}
        >
          {intl.formatMessage(
            {
              id: "A previous action may not have completed successfully. {br} Click the button below to send the development team the error log so that we can fix this error the soonest.",
              defaultMessage:
                "A previous action may not have completed successfully. {br} Click the button below to send the development team the error log so that we can fix this error the soonest.",
            },
            { br: <br /> }
          )}
        </Typography>
        <Button
          variant="contained"
          size="large"
          fullWidth={false}
          sx={{
            margin: "40px 0 20px",
            fontSize: "20px",
            fontWeight: 600,
            padding: "16px 35px",
          }}
          onClick={onReport}
        >
          {intl.formatMessage({
            id: "Send Error Report",
            defaultMessage: "Send Error Report",
          })}
        </Button>
        <Button
          color="secondary"
          size="medium"
          fullWidth={false}
          sx={{ textDecoration: "underline", fontSize: "16px" }}
          onClick={onRetry}
        >
          {intl.formatMessage({
            id: "Try again?",
            defaultMessage: "Try again?",
          })}
        </Button>
        <Button
          color="secondary"
          size="medium"
          fullWidth={false}
          sx={{ textDecoration: "underline", fontSize: "16px" }}
          href={ROUTE_DASHBOARD}
        >
          {intl.formatMessage({
            id: "Return to Home",
            defaultMessage: "Return to Home",
          })}
        </Button>
      </Stack>
    </>
  );
}
