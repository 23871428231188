export default () => ({
  defaultProps: {
    defaultExpanded: true,
  },
  styleOverrides: {
    // Name of the slot
    root: {
      "&.Mui-disabled": {
        backgroundColor: "transparent",
      },
      "&:before": {
        backgroundColor: "transparent",
      },
    },
  },
});
