import { useEffect } from "react";
import { useRouter } from "next/router";
import { AMPLITUDE_API_KEY, GTM_CONTAINER_ID } from "config/analytics";
import Analytics from "analytics";
import amplitudeAnalytics from "@analytics/amplitude";
import googleTagManager from "@analytics/google-tag-manager";
import getUserFromCookie from "services/auth/getUserFromCookie";

let analytics;
const isTrackable = AMPLITUDE_API_KEY || GTM_CONTAINER_ID;

if (isTrackable) {
  analytics = Analytics({
    app: "gj-new-emp",
    version: 1,
    plugins: [
      AMPLITUDE_API_KEY &&
        amplitudeAnalytics({
          apiKey: AMPLITUDE_API_KEY,
        }),
      GTM_CONTAINER_ID &&
        googleTagManager({
          containerId: GTM_CONTAINER_ID,
        }),
    ].filter((p) => p),
  });
}

function trackPage() {
  analytics.page();
}

export default function useAnalytics() {
  const router = useRouter();
  const userData = getUserFromCookie();
  useEffect(() => {
    if (isTrackable) {
      if (userData) {
        analytics.identify(userData.id, {
          posterId: userData.id,
          posterEmail: userData.user,
          posterCompany: userData.company_name,
          posterFirstName: userData.firstname,
          posterLastName: userData.lastname,
          posterCountry: userData.country,
          posterIndustry: userData.industry,
          posterSize: userData.size,
        });
      }
      trackPage();
      router.events.on("routeChangeComplete", trackPage);
    }

    return () => {
      if (isTrackable) {
        router.events.off("routeChangeComplete", trackPage);
      }
    };
  }, [router]);
}

export async function track(event, params = {}) {
  return analytics?.track(event, params);
}
