import { useState, createContext, useContext, useEffect } from "react";
import { readCookie } from "utils/cookie";
import { IntlProvider } from "react-intl";
import { setCookie } from "utils/cookie";

export const LocaleContext = createContext({
  locale: "en",
});

const cookieKey = "_gj_locale_new";

export const LocaleProvider = ({ children }) => {
  const cookieLocale = readCookie(cookieKey);
  const [locale, setLocale] = useState(cookieLocale || "en");
  const [messages, setMessages] = useState();

  const setCustomLocale = (locale) => {
    setLocale(locale);
    setCookie(null, null, cookieKey, locale);
  };

  const getCookieLocale = () => readCookie(cookieKey);

  useEffect(() => {
    setMessages(require(`../../translations/${locale}.json`));
    return () => setMessages(require(`../../translations/en.json`));
  }, [locale]);

  return (
    <LocaleContext.Provider
      value={{ locale, setLocale: setCustomLocale, getCookieLocale }}
    >
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export const useLocale = () => useContext(LocaleContext);
