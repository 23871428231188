import React, { forwardRef } from "react";
import CustomMenu from "components/CustomMenu";
import { useIntl } from "react-intl";
import MenuItem from "./menuItem";
import { ROUTE_RESOURCES, ROUTE_SUPPORT } from "config/routes";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { IconBook } from "@tabler/icons";

export default forwardRef(function Menu(props, ref) {
  const intl = useIntl();
  return (
    <CustomMenu ref={ref} onClose={() => ref.current.close()}>
      <MenuItem
        href={ROUTE_SUPPORT}
        icon={<HelpOutlineOutlinedIcon fontSize="small" />}
      >
        {intl.formatMessage({
          id: "Support Center",
          defaultMessage: "Support Center",
        })}
      </MenuItem>
      <MenuItem
        href={ROUTE_RESOURCES}
        icon={<IconBook width={24} height={24} />}
      >
        {intl.formatMessage({
          id: "Employer Resources",
          defaultMessage: "Employer Resources",
        })}
      </MenuItem>
    </CustomMenu>
  );
});
