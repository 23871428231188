export default (theme, spacing) => ({
  styleOverrides: {
    // Name of the slot
    root: {
      fontSize: theme.typography.subtitle1.fontSize,
      textTransform: "capitalize",
      color: theme.palette.black.main,
      "&.Mui-selected": {
        color: theme.palette.black.main,
        fontWeight: 500,
      },
      "& .MuiTabs-indicator": {
        backgroundColor: theme.palette.black.main,
      },
    },
  },
});
