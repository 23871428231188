import React, { forwardRef } from "react";
import { Divider, Typography, Link, Stack } from "@mui/material";
import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import NextLink from "next/link";
import { menus } from "./user-menu";
import Logout from "./UserMenuExtra/Logout";
import CustomMenu, { StyledMenuItem } from "components/CustomMenu";

export default forwardRef(function UserMenu({ user }, ref) {
  const intl = useIntl();

  const onClose = () => ref.current.close();

  return (
    <CustomMenu ref={ref} onClose={onClose}>
      <Stack sx={{ mx: 4, my: 2 }} gap={0}>
        <Typography color="text.secondary" fontSize={14} fontWeight={600}>
          {`${user?.firstname} ${user?.lastname}`}
        </Typography>
        <Typography
          color="text.secondary"
          fontSize={12}
          sx={{ textDecoration: "underline" }}
        >
          {user?.user}
        </Typography>
      </Stack>
      <Divider sx={{ my: 1 }} />
      {menus(intl).map(({ name, route, icon }) => (
        <NextLink key={route} href={route} passHref>
          <StyledMenuItem component={Link} key={name} size="small">
            {icon}
            <Typography
              component="span"
              variant="span"
              textAlign="center"
              sx={{ mx: 1 }}
              color="text.secondary"
            >
              {name}
            </Typography>
          </StyledMenuItem>
        </NextLink>
      ))}
      <Divider />
      <Logout component={StyledMenuItem} />
    </CustomMenu>
  );
});
