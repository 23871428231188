import React, { useRef } from "react";
import { Button } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Menu from "./menu";

export default function Help({ intl }) {
  const menuRef = useRef();
  const onOpen = (e) => menuRef.current.open(e.currentTarget);
  return (
    <>
      <Button
        color="black"
        startIcon={<HelpOutlineOutlinedIcon />}
        fullWidth={false}
        gap={0}
        onClick={onOpen}
      >
        {intl.formatMessage({ id: "Help", defaultMessage: "Help" })}
      </Button>
      <Menu ref={menuRef} />
    </>
  );
}
