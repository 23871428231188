import React, { createContext, useContext, useState } from "react";
import Loading from "components/Loading";

export const LoadingContext = createContext({});

export const LoadingProvider = ({ children, startLoading = false }) => {
  const [loading, showLoading] = useState(startLoading);

  return (
    <LoadingContext.Provider
      value={{
        loading,
        showLoading,
      }}
    >
      <Loading />
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);
