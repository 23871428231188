export default (theme) => ({
  defaultProps: {
    fullWidth: true,
  },
  styleOverrides: {
    root: {
      gap: theme.spacing(0.25),
      lineHeight: 1.2,
      fontSize: "0.95rem",
    },
  },
  variants: [
    {
      props: { variant: "profile-button" },
      style: ({ theme, ownerState }) => {
        const currentColor = theme.palette[ownerState.color];
        return {
          borderRadius: "10px",
          padding: theme.spacing(1, 2),
          justifyContent: "start",
          fontSize: 15,
          background: currentColor.main,
          color: currentColor.contrastText,
          textTransform: "none",
          px: 2,
          height: "40px",
          boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
          flexGrow: 1,
          "&:hover": {
            background: currentColor.dark,
          },
          "&[disabled]": {
            background: theme.palette.grey.light,
          },
        };
      },
    },
    {
      props: { variant: "flat" },
      style: ({ theme, ownerState }) => {
        const currentColor = theme.palette[ownerState.color];
        return {
          lineHeight: 1.2,
          fontSize: "0.95rem",
          boxShadow: "none",
          borderRadius: 10,
          color: currentColor.contrastText,
          backgroundColor: currentColor.main,
          padding: theme.spacing(1.1, 2.1),
          "&:hover": {
            boxShadow: "none",
            backgroundColor: currentColor.dark,
          },
        };
      },
    },
    {
      props: { variant: "filter-button" },
      style: ({ theme, ownerState }) => {
        const currentColor = theme.palette[ownerState.color];
        return {
          borderRadius: "10px",
          border: "1px solid",
          borderColor: currentColor.contrastText,
          padding: theme.spacing(1, 2),
          justifyContent: "start",
          fontSize: 12,
          background: currentColor.main,
          color: currentColor.contrastText,
          textTransform: "none",
          px: 2,
          columnGap: "4px",
          height: "30px",
          flexGrow: 1,
          "&:hover": {
            background: currentColor.dark,
          },
          "&[disabled]": {
            background: theme.palette.grey.light,
          },
        };
      },
    },
    {
      props: { variant: "ats-action" },
      style: ({ theme, ownerState }) => {
        const currentColor = theme.palette[ownerState.color];
        return {
          borderRadius: "10px",
          border: "2px solid",
          borderColor: currentColor.medium,
          padding: theme.spacing(1, 2),
          justifyContent: "start",
          fontSize: 16,
          background: currentColor.opacity,
          color: currentColor.dark,
          textTransform: "none",
          px: 2,
          columnGap: "4px",
          height: "35px",
          maxWidth: "160px",
          flexGrow: 1,
          "&:hover": {
            background: currentColor.lightest,
          },
          "&[disabled]": {
            background: theme.palette.grey.light,
          },
        };
      },
    },
  ],
});
