import { red, grey, green } from "@mui/material/colors";

export default {
  primary: {
    main: "#F15E5F",
    light: "#FFF8F8",
  },
  secondary: {
    main: "#5452F6",
    light: "rgba(84, 82, 246, 0.2)",
  },
  secondaryDark: {
    main: "#5452F6",
  },
  secondaryTransparent: {
    main: "rgba(84, 82, 246, 0.2)",
  },
  danger: {
    main: "#F15E5F",
    contrastText: "#fff",
  },
  orange: {
    main: "#E79F14",
  },
  yellow: {
    main: "#FFBD00",
    light: "#ffcc00",
    dark: "#A37900",
    contrastText: "#000",
  },
  brown: {
    light: "#E79F13",
  },
  dark: {
    main: "#5f5f5f",
  },
  white: {
    main: "#FFFFFF",
    contrastText: grey[700],
  },
  error: {
    main: red.A400,
  },
  chipGrey: {
    main: grey[500],
    contrastText: "#FFFFFF",
  },
  grey: {
    main: grey[700],
    darker: grey[600],
    dark: grey[500],
    medium: grey[400],
    light: grey[300],
    moreLight: "#F3F3F5",
    contrastText: "#FFFFFF",
  },
  black: {
    main: "#313638",
    contrastText: "#FFFFFF",
    light: "#181B1C",
  },
  greyLight: {
    main: grey[400],
    contrastText: "#FFFFFF",
  },
  greyPale: {
    main: grey[300],
    dark: grey[400],
    contrastText: "#181B1C",
  },
  pink: {
    main: "#F9E2E2",
    light: "#FDF6F6",
  },
  blue: {
    main: "#5452F6",
    dark: "#5452F6",
    contrastText: "#fff",
    medium: "#3775dc",
    light: "#2097F6",
    lighter: "rgba(84, 82, 246, 0.25)",
    opacity: "rgba(84, 82, 246, 0.05)",
    moreLight: "rgb(84, 82, 246)",
    veryLight: "#e7e7fe",
    withoutOpacity: "rgba(234,234,251,1)",
  },
  purple: {
    main: "#8a00c2",
    dark: "#4c00b0",
    light: "#ca5cdd",
  },
  promote_blue: {
    main: "#5452F6",
    contrastText: "#fff",
  },
  green: {
    main: green[500],
    lightest: green[100],
    light: green[200],
    dark: green[700],
    medium: "#12B829",
    opacity: "rgba(18, 184, 41, 0.05)",
    contrastText: "#FFFFFF",
  },
  menu: {
    active: "#F15E5F",
    inactive: "#7A7A7B",
  },
  menuBackground: {
    active: "#FDF8F8",
    inactive: "transparent",
  },
  border: {
    active: {
      main: "#5452F6",
    },
    inactive: {
      main: "#D9D9D9",
    },
  },
  ratingStar: {
    main: "#6E7EE4",
  },
  placeholderBackground: {
    main: "#C4C4C4",
  },
  layout: {
    main: "#F9F5F5",
  },
  transparent: { main: "rgba(0,0,0,0)" },
  transparentGreen: { main: "#12B82933" },
  topIndicator: {
    main: "#FAECD0",
    higher: "#DDDCFD",
    highest: "#FCDFDF",
    low: "#DDDCFD",
  },
};
