import React, { forwardRef } from "react";
import getLanguages from "./languages";
import { useLocale } from "hooks/useLocale";
import CustomMenu from "components/CustomMenu";

export default forwardRef(function LanguageMenu({ intl }, ref) {
  const { setLocale } = useLocale();
  const languages = getLanguages(intl);
  const onClose = () => ref.current.close();

  const onChange = (code) => {
    setLocale(code);
    onClose();
  };

  return (
    <CustomMenu
      ref={ref}
      onClose={onClose}
      options={languages.map((l) => ({
        ...l,
        label: `${l.code?.toUpperCase()} - ${l.title}`,
      }))}
      optionValue="code"
      onChange={onChange}
    />
  );
});
