export default (theme) => ({
  variants: [
    {
      props: { variant: "close-only" },
      style: {
        padding: theme.spacing(0.5, 2),
        paddingBottom: 0,
      },
    },
  ],
});
