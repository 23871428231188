export const APP_HOST =
  process.env.NEXT_PUBLIC_APP_HOST ?? "https://jobio-staging.grabjobs.co";

export const APP_COOKIE_MAX_AGE = process.env.APP_COOKIE_MAX_AGE ?? 21600;

export const APP_SECRET = process.env.APP_SECRET ?? "vneY9zUdu5CSKsuNAf";

export const MSG_ID = process.env.MSG_ID ?? "6a19e72ce9a57d9cbaaab139132a63d2";
export const APP_TRACK_DESK = "cafd1309-1efa-40c7-bdb1-59f2b3d47349";
export const STRIPE_API_KEY =
  process.env.NEXT_PUBLIC_STRIPE_KEY ??
  "pk_test_t2eE0PYIUnWWyxkIC5qmXVPm00pubZsWP2";

export const REGISTER_SOURCE = process.env.REGISTER_SOURCE ?? "website";

export const drawerWidth = 220;
export const collapsedDrawerWidth = 80;
export const mobileDrawerWidth = 240;

export const JOB_SEEKER_WEB_URL =
  process.env.NEXT_PUBLIC_JOB_SEEKER_WEB_URL ?? "https://staging.grabjobs.co";

export const WEB_CHAT_BASE_URL =
  process.env.NEXT_PUBLIC_WEB_CHAT_BASE_URL ?? APP_HOST;

export const V1_URL =
  process.env.NEXT_PUBLIC_V1_URL ?? "https://employer-staging.grabjobs.co";
export const V2_URL =
  process.env.NEXT_PUBLIC_V2_URL ?? "https://employer-v2-staging.grabjobs.co";
export const IPINFO_TOKEN =
  process.env.NEXT_PUBLIC_IPINFO_TOKEN ?? "74887badcc5733";

export const NEW_USER_PLAN_ID = process.env.NEXT_PUBLIC_NEW_USER_PLAN_ID ?? 4;

export const SHOW_HRMS = process.env.NEXT_PUBLIC_SHOW_HRMS ?? "false";

export const SENDBIRD_APP_ID =
  process.env.NEXT_PUBLIC_SENDBIRD_APP_ID ??
  "5443D7A4-1AAA-4375-A40F-20273DB24571";

export const PIN_SLOTS = process.env.NEXT_PUBLIC_PIN_SLOTS ?? 10;

export const PIN_JOB_REQUIRE_COINS =
  process.env.NEXT_PUBLIC_PIN_JOB_REQUIRE_COINS ?? 30;

export const FILE_SIZE_LIMIT = 5000000;
