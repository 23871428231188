import { useState, createContext, useContext, useEffect } from "react";
import getUserFromCookie from "services/auth/getUserFromCookie";
import { API_PROFILE } from "config/api";
import useCustomSWR from "utils/use-swr";
import { API_DASHBBOARD } from "config/api";
import fetcher from "utils/fetcher";
import { API_PLAN_ACTIVE } from "config/api";

export const UserContext = createContext({});

export const UserProvider = ({ userData = getUserFromCookie(), children }) => {
  const [user, setUser] = useState(userData);
  useEffect(() => setUser(userData), [userData]);

  const {
    data: profileUser,
    mutate: mutateProfile,
    isValidating: isUserValidating,
  } = useCustomSWR(
    user.user && [API_PROFILE, { user: user.user, session: user.session }],
    fetcher
  );
  const {
    data: profileDashboard,
    isValidating: isDashboardLoading,
    mutate: mutateDashboard,
  } = useCustomSWR(
    user.user && [API_DASHBBOARD, { user: user.user, session: user.session }],
    fetcher,
    { revalidateOnFocus: true }
  );
  const {
    data: subscriptionDetail,
    mutate: mutatePlan,
    isValidating: isPlanValidating,
  } = useCustomSWR(
    user.user && [API_PLAN_ACTIVE, { user: user.user, session: user.session }],
    fetcher
  );

  const mutateAll = () => {
    mutateProfile();
    mutatePlan();
  };

  return (
    <UserContext.Provider
      value={{
        user: {
          ...user,
          ...profileUser,
          isUserValidating,
          subscriptionDetail: { ...subscriptionDetail, isPlanValidating },
          mutate: mutateAll,
        },
        dashboard: {
          ...profileDashboard,
          isDashboardLoading,
          mutate: mutateDashboard,
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
