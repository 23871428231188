import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";

import CssBaseline from "@mui/material/CssBaseline";
import PropTypes from "prop-types";

import Head from "next/head";

import theme from "styles/theme";
import createEmotionCache from "createEmotionCache";
import NextNProgress from "nextjs-progressbar";

import { LocaleProvider } from "hooks/useLocale";
import { UserProvider } from "hooks/useUser";
import { LoadingProvider } from "hooks/useLoading";
import { SnackbarProvider } from "notistack";
import App from "next/app";

import useAnalytics from "hooks/useAnalytics";
import useFacebookPixel from "hooks/useFacebookPixel";

import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@mui/x-date-pickers";
import "components/Calendar/style.css";
import "components/Manage/Candidate/CandidateList/Mobile/customize-swiper.css";

import { getUserFromCookie } from "services/auth";
import Zendesk from "components/Zendesk";

import ErrorBoundary from "components/ErrorBoundary";

function MyApp(props) {
  const {
    user,
    Component,
    emotionCache = createEmotionCache(),
    pageProps,
  } = props;

  useFacebookPixel();
  useAnalytics();

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{Component.title ?? "Home"} ● GrabJobs</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="icon" href="/favicon.ico" />
        <link href="https://fonts.google.com/share?selection.family=Open%20Sans:wght@300%7CRubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500"></link>
      </Head>
      <NextNProgress color="#F15E5E" />
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <UserProvider userData={user}>
          <LocaleProvider>
            <LoadingProvider>
              <SnackbarProvider
                variant="success"
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={3000}
              >
                <ErrorBoundary>
                  <Component {...pageProps} />
                </ErrorBoundary>
              </SnackbarProvider>
            </LoadingProvider>
            <Zendesk />
          </LocaleProvider>
        </UserProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const user = getUserFromCookie(appContext.ctx.req);

  return { ...appProps, user };
};

export default MyApp;
