import { APP_HOST } from "./app";

export const API_LOGIN_URL = `${APP_HOST}/v3/poster/login`;
export const API_REGISTER_URL = `${APP_HOST}/v3/poster/register`;
export const API_VERIFY_URL = `${APP_HOST}/v4/poster/verify-email`;
export const API_RESEND_EMAIL_URL = `${APP_HOST}/v4/poster/resend-verify-email`;
export const API_RESET_PASSWORD_URL = `${APP_HOST}/v3/poster/password/reset_post`;

export const API_PROFILE = `${APP_HOST}/v3/poster/profile`;
export const API_COIN_BALANCE = `${APP_HOST}/v3/poster/balance`;
//Chats
export const API_START_CHAT = `${APP_HOST}/v4/poster/start-chat`;
//settings
export const API_EMAIL_SETTING = `${APP_HOST}/v4/poster/email_setting`;
export const API_UPDATE_EMAIL_SETTING = `${APP_HOST}/v4/poster/email_setting_update`;
export const API_CHANGE_PASSWORD = `${APP_HOST}/v3/poster/password_post`;

//dashboard
export const API_DASHBBOARD = `${APP_HOST}/v4/poster/smart-dashboard-new`;
export const API_DASHBBOARD_JOB_RECORD = `${APP_HOST}/v3/poster/job/records`;
export const API_DASHBBOARD_PROMOTION = `${APP_HOST}/v4/poster/get-promotion-popup`;

//brand
export const API_BRAND_CREATE = `${APP_HOST}/v3/poster/brand/add`;
export const API_BRAND_LIST = `${APP_HOST}/v3/poster/brand`;
export const API_BRAND_DETAIL = `${APP_HOST}/v3/poster/brand/detail`;
export const API_BRAND_DELETE = `${APP_HOST}/v3/poster/brand/delete`;
export const API_BRAND_GALLERY = `${APP_HOST}/v3/poster/gallery`;
export const API_BRAND_GALLERY_UPDATE = `${APP_HOST}/v3/poster/brand/gallery/photo`;
export const API_REMOVE_PHOTO_FROM_GALLERY = `${APP_HOST}/v3/poster/brand/gallery/delete-photo`;

//brand update
export const API_BRAND_UPDATE = `${APP_HOST}/v3/poster/brand/update`;
export const API_BRAND_UPDATE_BANNER = `${APP_HOST}/v3/poster/brand/banner-photo`;
export const API_BRAND_UPDATE_LOGO = `${APP_HOST}/v3/poster/brand/photo`;
export const API_BRAND_UPDATE_VIDEO = `${APP_HOST}/v3/poster/brand/video_link`;

//outlet
export const API_BRAND_OUTLET_LIST = `${APP_HOST}/v3/poster/brand/brand-outlet`;
export const API_BRAND_OUTLET_DELETE = `${APP_HOST}/v3/poster/brand/brand-outlet/delete`;
export const API_BRAND_OUTLET_CREATE = `${APP_HOST}/v3/poster/brand/brand-outlet/create`;

//plan
export const API_PLAN_ACTIVE = `${APP_HOST}/v3/poster/get-active-subscription`;
export const API_CV_PLANS = `${APP_HOST}/v1/billing/plans`;

export const API_PLAN_LIST = `${APP_HOST}/v4/poster/get-payment-ui-plans`;
export const API_PLAN_LISTS = `${APP_HOST}/v4/poster/get-payment-plans`;
export const API_PLAN_SUBSCRIBE = `${APP_HOST}/v3/poster/subscribe`;
export const API_PLAN_SUBSCRIBE_3DS = `${APP_HOST}/v4/poster/subscribe`;
export const API_CV_PLAN_SUBSCRIBE = `${APP_HOST}/v1/billing/subscription`;
export const API_PLAN_UPDATE = `${APP_HOST}/v3/poster/change-subscription`;
export const API_PLAN_UPDATE_3DS = `${APP_HOST}/v4/poster/change-subscription`;
export const API_PLAN_CANCEL = `${APP_HOST}/v3/poster/cancel-subscription`;
export const API_PLAN_PREVIEW_COST = `${APP_HOST}/v3/poster/preview-subscription`;
export const API_PLAND_CANCEL_FEEDBACK = `${APP_HOST}/v4/poster/save-poster-feedback`;
export const API_HAS_PAYMENT_METHOD = `${APP_HOST}/v4/poster/has-payment-method`;
export const API_BILLING_PORTAL = `${APP_HOST}/v4/poster/stripe/customer-portal`;
export const API_PLAN_ADDON = `${APP_HOST}/v4/poster/get-addon-plans`;
export const API_REGISTER_CV_PLAN = `${APP_HOST}/v4/poster/cv-search/register`;
export const API_CHANGE_CV_PLAN = `${APP_HOST}/v1/billing/subscription/reset`;
export const API_CANCEL_CV_PLAN = `${APP_HOST}/v1/billing/subscription/cancel`;
//coin
export const API_COIN_PLAN = `${APP_HOST}/v4/poster/get-coin-plan`;
export const API_GET_COINS_PLAN = `${APP_HOST}/v4/poster/get-coin-plans`;
export const API_PURCHASE_COIN = `${APP_HOST}/v3/poster/purchase-coins`;

//coupon
export const API_VALIDATE_COUPON = `${APP_HOST}/v4/poster/validate-coupon`;
export const API_VALIDATE_BILLING_COUPON = `${APP_HOST}/v1/billing/validate-coupon`;
//job categories
export const API_JOB_CATEGORY_LIST = `${APP_HOST}/v3/poster/business_type_table`;

//job titles
export const API_JOB_TITLE_LIST = `${APP_HOST}/v3/poster/job/job-titles`;
export const API_GET_JOB_TITLE = `${APP_HOST}/v3/poster/job/job-title`;
export const API_GET_JOB_TITLE_OPTIONS = `${APP_HOST}/v3/poster/job/job-title/get-all`;

//bot template
export const API_BOT_TEMPLATE = `${APP_HOST}/v3/poster/job/template/interview-bot/get`;

//bot template requirement
export const API_BOT_TEMPLATE_REQUIREMENT = `${APP_HOST}/v3/poster/job/requirements/get-from-template`;
//work schedule
export const API_WORK_SCHEDULE_OPTIONS = `${APP_HOST}/v4/poster/get-work-schedule`;
//job tags
export const API_JOB_TAG_LIST = `${APP_HOST}/v4/poster/get-tagging-list`;
// AI job description generator
export const API_GENERATE_JOB_DESCRIPTION = `${APP_HOST}/v4/poster/get-ai-desc`;
// AI salary range generator
export const API_GENERATE_SALARY_RANGE = `${APP_HOST}/v4/poster/get-ai-salary`;
//benefit & perks
export const API_BENEFIT_PERKS = `${APP_HOST}/v4/benefit/view-benefits`;
//Application
export const API_POST_MOVE_STAGE = `${APP_HOST}/v4/poster/move-applicants`;
export const API_POST_UPLOAD_CSV = `${APP_HOST}/v4/poster/upload-csv`;

//Candidates
export const API_GET_CANDIDATE_OVERVIEW_LIST_BY_JOB = `${APP_HOST}/v4/poster/job/applicants-overview`;
export const API_GET_CANDIDATE_LIST_BY_JOB = `${APP_HOST}/v4/poster/job/applicants`;
export const API_GET_CANDIDATE_DETAIL = `${APP_HOST}/v3/poster/job/applicant/detail`;
export const API_CANDIDATE_REQUEST_CV = `${APP_HOST}/v4/poster/request-cv`;
export const API_CANDIDATE_REQUEST_REFERENCES = `${APP_HOST}/v4/poster/request-recommend`;
export const API_CANDIDATE_GENERATE_CV_HTML = `${APP_HOST}/v4/poster/generate-cv-html`;
export const API_CANDIDATE_GET_CV = `${APP_HOST}/v4/poster/job/applicant/resume`;
export const API_GET_CANDIDATES = `${APP_HOST}/v3/poster/search/seeker`;
export const API_GET_LOCATIONS = `${APP_HOST}/v4/seeker/all-seo-location`;
export const API_GET_PRE_DOWNLOAD_DATA = `${APP_HOST}/v3/poster/applicants/pre-download-stage-csv`;
export const API_TRACK_CV_DOWNLOAD = `${APP_HOST}/v4/poster/seeker-cv/download`;
export const API_GET_CV_SUBSCRIPTION = `${APP_HOST}/v1/billing/subscription/active`;
//Employer
export const API_POST_UPDATE_EMPLOYER_INFO = `${APP_HOST}/v3/poster/mobile_post`;
export const API_GET_TEAM_MEMBERS = `${APP_HOST}/v3/poster/admin`;
export const API_POST_CREATE_SUB_ACCOUNT = `${APP_HOST}/v3/poster/register`;
export const API_UPDATE_TEAM_MEMBER = `${APP_HOST}/v3/poster/admin/update`;
export const API_DELETE_TEAM_MEMBER = `${APP_HOST}/v3/poster/admin/delete`;
export const API_GET_POSTER_DETAILS = `${APP_HOST}/v4/poster/get-poster-details`;
export const API_GET_POSTER_EXTRA_DETAIL = `${APP_HOST}/v4/poster/get-details`;
export const API_GET_POSTER_STATS = `${APP_HOST}/v4/poster/stats`;
export const API_POST_UPDATE_INDUSTRY_SIZE = `${APP_HOST}/v3/poster/company/update`;
export const API_POST_POSTER_DETAILS_INDUSTRY_COMPANY = `${APP_HOST}/v4/poster/save-details`;
export const API_POST_SAVE_ONBOARDING_UPDATES = `${APP_HOST}/v4/poster/onboarding`;
export const API_VERIFY_PHONE = `${APP_HOST}/v4/poster/sms`;

//Shortlist
export const API_GET_SHORTLIST_LIST = `${APP_HOST}/v3/poster/shortlist`;
export const API_GET_SHORTLIST_LIST_MSG = `${APP_HOST}/v4/poster/get-shortlist-message`;
export const API_CREATE_SHORTLIST = `${APP_HOST}/v3/poster/shortlist/add`;
export const API_POST_SEEKER_SHORTLIST = `${APP_HOST}/v3/poster/shortlist/seeker/add`;
export const API_GET_MY_CANDIDATES = `${APP_HOST}/v4/poster/get-shortlist`;
export const API_GET_LATEST_AVAILABLE_CANDIDATES = `${APP_HOST}/v3/poster/latest-available-candidates`;
export const API_POST_SEEKER_IDS_SHORTLIST = `${APP_HOST}/v3/poster/shortlist/seeker-ids/add`;
export const API_POST_SEEKER_REJECT = `${APP_HOST}/v4/poster/reject-applicants`;
export const API_POST_SEEKER_TOP_MATCH_SHORTLIST = `${APP_HOST}/v3/poster/shortlist/top-matching`;
export const API_EDIT_SHORTLIST = `${APP_HOST}/v3/poster/shortlist/edit`;
export const API_REMOVE_SEEKER_SHORTLIST = `${APP_HOST}/v3/poster/shortlist/seeker/delete`;
export const API_DELETE_SHORTLIST = `${APP_HOST}/v3/poster/shortlist/delete`;
//autoamtion

export const API_AUTOMATIC_SHORTLIST = `${APP_HOST}/v4/poster/get-automation-shortlist`;
export const API_AUTOMATIC_INTERVIEW = `${APP_HOST}/v4/poster/get-automation-interview`;
export const API_UPDATE_AUTOMATION_SHORTLIST = `${APP_HOST}/v4/poster/update-automation-shortlist`;
export const API_UPDATE_AUTOMATION_INTERVIEW = `${APP_HOST}/v4/poster/update-automation-interview`;
//job
export const API_JOB_LIST = `${APP_HOST}/v3/poster/job/records`;
export const API_GET_JOB = `${APP_HOST}/v3/poster/job/get-for-edit`;
export const API_POST_BUMP_JOBS = `${APP_HOST}/v3/poster/job/bump`;
export const API_CREATE_JOB = `${APP_HOST}/v3/poster/job/requirements/create`;
export const API_UPDATE_JOB = `${APP_HOST}/v3/poster/job/requirements/update`;
export const API_POST_CLOSE_JOB = `${APP_HOST}/v3/poster/job/close`;
export const API_POST_REOPEN_JOB = `${APP_HOST}/v3/poster/job/reopen`;
export const API_GET_AUTOMATION_JOB = `${APP_HOST}/v3/poster/job/automation`;
export const API_APPLICANT_BOOSTER = `${APP_HOST}/v3/poster/applicant-booster`;
export const API_JOB_STATS = `${APP_HOST}/v4/poster/job/stats`;
export const API_JOB_BOOST = `${APP_HOST}/v4/poster/job/boost-view`;
export const API_JOB_BOOSTED = `${APP_HOST}/v4/poster/job/boosted`;
export const API_JOB_PIN = `${APP_HOST}/v4/poster/job/pinned`;
export const API_REMIND_PIN_JOB = `${APP_HOST}/v4/poster/job/pinned/remind`;

//Seeker
export const API_SEEKER_RECOMMEND_LIST = `${APP_HOST}/v1/job-match/get-recommended-seekers`;

//Calendar
export const API_CALENDAR_CHECK_TIMESLOT = `${APP_HOST}/v4/poster/available-timeslots/calender`;
export const API_CALENDAR_CREATE_TIMESLOT = `${APP_HOST}/v4/poster/timeslots`;
export const API_CALENDAR_GET_TIMESLOT = `${APP_HOST}/v4/poster/timeslots`;
export const API_CALENDAR_DELETE_TIMESLOT = `${APP_HOST}/v4/poster/delete-timeslot`;
export const API_CALENDAR_GET_CALENDAR = `${APP_HOST}/v4/poster/view-poster-calendars`;
export const API_CALENDAR_CREATE_CALENDAR = `${APP_HOST}/v4/poster/create-calendar`;
export const API_CALENDAR_DELETE_CALENDAR = `${APP_HOST}/v4/poster/delete-calendar`;
export const API_CALENDAR_EDIT_CALENDAR = `${APP_HOST}/v4/poster/edit-calendar`;

//Seeker
export const API_CALENDAR = `${APP_HOST}/v3/calendar`;
export const API_SEEKER_GET_OPTIONS = `${APP_HOST}/v3/seeker/get-dynamic-profile-options`;

//Data
export const API_GET_NOTE_LIST = `${APP_HOST}/v3/poster/candidate/note`;
export const API_POST_NOTE_LIST = `${APP_HOST}/v3/poster/candidate/note`;

//Poster calendar
export const API_GET_CALENDAR = `${APP_HOST}/v4/poster/view-poster-calendars`;

//interview
export const API_INTERVIEW_CREATE = `${APP_HOST}/v4/poster/create-interview-invite`;
export const API_INTERVIEW_CANCEL = `${APP_HOST}/v4/poster/cancel-interview`;
// Share Email

export const API_POST_SHARE_PROFILE_EMAIL = `${APP_HOST}/v3/poster/share-applicant-info`;
export const API_POST_CREATE_SHORTLIST = `${APP_HOST}/v3/poster/shortlist/add`;

//Filters
export const API_GET_SKILL_OPTIONS = `${APP_HOST}/v3/seeker/skill_table`;
export const API_GET_LANGUAGE_OPTIONS = `${APP_HOST}/v3/poster/language`;

//Analytics
export const API_GET_ANALYTICS = `${APP_HOST}/v4/poster/analytics`;
export const API_GET_ANALYTICS_GRAPH = `${APP_HOST}/v4/poster/analytics-graph`;

//Application History
export const API_GET_APPLICATION_HISTORY = `${APP_HOST}/v4/poster/get-seeker-applications`;

//Lead creation
export const API_POST_LEAD = `${APP_HOST}/v4/poster/add-poster-leads`;

export const API_POST_ADD_SHORTLIST_MSG = `${APP_HOST}/v4/poster/add-shortlist-message`;

// Offer System
export const API_POST_OFFER_SEEKER = `${APP_HOST}/v4/poster/offer-seeker`;
export const API_POST_SEND_OFFER = `${APP_HOST}/v4/poster/offer-seeker/send`;
export const API_EXPORT_CANDIDATES = `${APP_HOST}/v3/poster/applicants/pre-download-stage-csv`;
export const API_DOWNLOAD_EXPORT_CANDIDATES = `${APP_HOST}/v3/poster/applicants/download-stage-csv`;
//ATS

export const API_GET_OPPORTUNITIES = `${APP_HOST}/v4/poster/view-opportunities`;
export const API_UPDATE_OPPORTUNITIES = `${APP_HOST}/v4/poster/update-opportunities`;
export const API_JOB_TITLE_SUGGESTION = `${APP_HOST}/v4/poster/view-title-suggestion`;
