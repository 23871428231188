import React from "react";
import RZendesk from "react-zendesk";
import { ZENDESK_KEY } from "config/analytics";
import { useIntl } from "react-intl";

export default function Zendesk() {
  if (!ZENDESK_KEY) {
    return null;
  }
  const intl = useIntl();

  const setting = {
    color: {
      theme: "#000",
    },
    launcher: {
      chatLabel: {
        "en-US": "Need Help",
      },
    },
  };
  return <RZendesk defer zendeskKey={ZENDESK_KEY} {...setting} />;
}
