export default (theme) => ({
  variants: [
    {
      props: { variant: "job-post" },
      style: {
        backgroundColor: theme.palette.primary.light,
        border: `1px solid ${theme.palette.primary.main}`,
        marginBottom: theme.spacing(2),
        borderRadius: 5,
        "& .MuiAccordionSummary-content.Mui-expanded": {
          margin: theme.spacing(1, 0),
        },
        "& .MuiTypography-h6": {
          fontWeight: 600,
          fontSize: 18,
        },
      },
    },
  ],
});
