import { postFetcher } from "utils/fetcher";
import { API_RESEND_EMAIL_URL } from "config/api";
import { msgId } from "utils/hash";

export default function resendActivationEmail(email) {
  return postFetcher(API_RESEND_EMAIL_URL, {
    email,
    msg_id: msgId(),
  });
}
