import React, { useRef } from "react";
import {
  Box,
  Link,
  AppBar,
  Toolbar,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";
import LanguageSelect from "components/LanguageSelect/icon";
import Logo from "components/Logo";
import MenuIcon from "@mui/icons-material/Menu";
import { useUser } from "hooks/useUser";
import UserMenu from "./Menu/UserMenu";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {
  ROUTE_PREVIOUS_PLATFORM,
  ROUTE_SETTINGS,
  ROUTE_DASHBOARD,
} from "config/routes";
import { useIntl } from "react-intl";
import NextLink from "next/link";
import Help from "./Help";

export default function ({ onDrawerToggle }) {
  const menuRef = useRef();
  const { user } = useUser();
  const onOpenUserMenu = (e) => menuRef.current.open(e.currentTarget);

  const intl = useIntl();

  return (
    <AppBar
      position="fixed"
      elevation={0}
      color="white"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <Stack
          direction="row"
          justifyContent="space-between"
          flexGrow={1}
          alignItems="center"
        >
          <Stack direction="row" alignItems="center">
            <NextLink href={ROUTE_DASHBOARD} passHref>
              <Link>
                <Logo />
              </Link>
            </NextLink>
          </Stack>
          <Stack direction="row" justifyContent="flex-end">
            <Help intl={intl} />
            <Stack direction="row" gap={1}>
              <LanguageSelect sx={{ display: { xs: "none", sm: "flex" } }} />
              <IconButton
                edge="start"
                href={ROUTE_SETTINGS}
                sx={{ display: { xs: "none", sm: "flex" } }}
                title={intl.formatMessage({
                  id: "Settings",
                  defaultMessage: "Settings",
                })}
              >
                <SettingsOutlinedIcon color="black" />
              </IconButton>
              <IconButton
                edge="start"
                onClick={onOpenUserMenu}
                sx={{ display: { xs: "none", sm: "flex" } }}
                title={intl.formatMessage({
                  id: "Account",
                  defaultMessage: "Account",
                })}
              >
                <AccountCircleOutlinedIcon color="black" />
              </IconButton>
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={onDrawerToggle}
                sx={{ display: { sm: "none" } }}
              >
                <MenuIcon color="black" />
              </IconButton>
              <UserMenu ref={menuRef} user={user} />
            </Stack>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
