import { useEffect } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { FB_PIXEL_API_KEY } from "config/analytics";

const ReactPixel = dynamic(() => import("react-facebook-pixel"), {
  ssr: false,
});

export default function useFacebookPixel() {
  const router = useRouter();

  const trackPage = () => {
    ReactPixel.pageView();
  };

  useEffect(() => {
    if (FB_PIXEL_API_KEY) {
      ReactPixel.init(FB_PIXEL_API_KEY); // facebookPixelId
      trackPage();
      router.events.on("routeChangeComplete", trackPage);
    }

    return () => {
      if (FB_PIXEL_API_KEY) {
        router.events.off("routeChangeComplete", trackPage);
      }
    };
  }, [router]);
}
