export default (theme) => ({
  styleOverrides: {
    // Name of the slot
    root: {
      // Some CSS
      marginBottom: theme.spacing(1),
      fontWeight: 600,
    },
  },
});
