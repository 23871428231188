import React, { memo } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useLoading } from "hooks/useLoading";

export default memo(() => {
  const { loading } = useLoading();

  return (
    <Backdrop
      open={loading}
      sx={{
        zIndex: (theme) => theme.zIndex.modal + 10,
      }}
    >
      <CircularProgress color="white" />
    </Backdrop>
  );
});
