import { msgId } from "utils/hash";
import { API_RESET_PASSWORD_URL } from "config/api";
import { postFetcher } from "utils/fetcher";

export default async function resetPassword(user) {
  return postFetcher(API_RESET_PASSWORD_URL, {
    user,
    msg_id: msgId(),
  });
}
