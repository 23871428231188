import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import PowerIcon from "@mui/icons-material/Power";
import {
  ROUTE_PLANS,
  ROUTE_MEMBERS,
  ROUTE_SETTINGS,
  ROUTE_PLUGINS,
  ROUTE_BRAND_PROFILE,
} from "config/routes";

export const planMenu = (intl) => ({
  name: intl.formatMessage({
    id: "Plan & Billing",
    defaultMessage: "Plan & Billing",
  }),
  route: ROUTE_PLANS,
  icon: <CreditCardOutlinedIcon color="inherit" />,
});

const settingMenu = (intl) => ({
  name: intl.formatMessage({
    id: "Settings",
    defaultMessage: "Settings",
  }),
  route: ROUTE_SETTINGS,
  icon: <SettingsOutlinedIcon color="inherit" />,
});

const brandProfileMenu = (intl) => ({
  name: intl.formatMessage({
    id: "Brand Profiles",
    defaultMessage: "Brand Profiles",
  }),
  route: ROUTE_BRAND_PROFILE,
  icon: <CorporateFareOutlinedIcon color="inherit" />,
});

const teamMemberMenu = (intl) => ({
  name: intl.formatMessage({
    id: "Team Members",
    defaultMessage: "Team Members",
  }),
  route: ROUTE_MEMBERS,
  icon: <PeopleOutlinedIcon color="inherit" />,
});

const pluginsMenu = (intl) => ({
  name: intl.formatMessage({
    id: "Plugins",
    defaultMessage: "Plugins",
  }),
  route: ROUTE_PLUGINS,
  icon: <PowerIcon />,
});

export const menus = (intl) => [
  brandProfileMenu(intl),
  teamMemberMenu(intl),
  planMenu(intl),
];

export const mobileMenus = (intl) => [
  settingMenu(intl),
  brandProfileMenu(intl),
  teamMemberMenu(intl),
  // pluginsMenu(intl),
];

export const secondMenus = (intl) => [
  // pluginsMenu(intl),
];
