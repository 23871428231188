import { useEffect, useState } from "react";

export default function useSessionStorage(key) {
  const [values, setValues] = useState({});
  useEffect(() => {
    const v = window.sessionStorage.getItem(key);
    if (v) {
      setValues(JSON.parse(v));
    }
  }, []);

  return values;
}

export function clearSessionStorage() {
  return window.sessionStorage.clear();
}
